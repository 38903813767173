import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import Conatctus from './Conatctus';

function Ampleudyam() {
    const [isOpen, setIsOpen] = useState(false)
    const [isContact, setIsContact] = useState(false)
    const toggleMenu = () => {
        setIsOpen((open) => !open)
    }
    const handleModal = () => {
        console.log(isContact);
        setIsContact((open) => !open); // Update parent state to close modal
        console.log(isContact);
    };
    return (
        <div>
            {isOpen === false ?
                <div>
                    <div className='flex top-5 w-full justify-between sm:mt-[3vh] lg:mt-0'>
                        <div className='flex md:ml-10 sm:ml-7'>
                            <Link to="/"><img className='sm:w-[30vw] md:w-[20vw] lg:w-[15vw]' alt="logo" src="/logo_c.png" /></Link>
                        </div>
                        <div className='flex md:mt-2 lg:mt-5 md:flex md:items-center md:mr-8 lg:mr-16 sm:hidden'>
                            <Link className='md:mr-2 md:text-lg lg:mr-10 lg:text-xl font-POP font-semibold' to="/company">Company</Link>
                            <Link className='md:mr-2 md:text-lg lg:mr-10 lg:text-xl font-POP font-semibold' to="/ampleudyam">Udyam</Link>
                            <Link className='md:mr-2 md:text-lg lg:mr-10 lg:text-xl font-POP font-semibold' to="/amplewealth">Savings</Link>
                        </div>
                        <div className='sm:flex sm:items-end md:hidden' onClick={toggleMenu}>
                            <img className='sm:w-[10vw] mr-5' alt="links" src="/menu.png" />
                        </div>
                    </div>
                    <div className='sm:mt-[10vh] lg:mt-[15vh] mb-20'>
                        <p className='font-POP text-3xl font-bold text-center mb-16'>Ample Udyam</p>
                        <p className='ml-[10vw] mr-[10vw] text-center font-POP'>We offer credit for livestock inputs, quick disbursement, and flexible repayments through partner retailers. Our platform ensures growth financing, insurance, and escrow-based collections for real-time payments, optimizing cash flow while providing market access and fair pricing for farmers.</p>
                    </div>
                    <div className=''>
                        <p className='text-center text-gray-600 font-POP mb-[8vh]'>What we provide?</p>
                        <div className='sm:ml-[10vw] lg:ml-[15vw]'>
                            <div className='flex md:mb-[10vh] sm:flex-col md:flex-row'>
                                <div className='sm:w-[80vw] sm:mb-[5vh] md:w-[25vw] md:mb-0'>
                                    <div>
                                        <img alt="credit" src="/credit-access.png" className='w-[15vh] h-[15vh] sm:ml-[25vw] md:ml-[7vw] mb-[2vh]' />
                                    </div>
                                    <div>
                                        <p className='font-POP text-center text-2xl'>Credit Access</p>
                                    </div>
                                    <div>
                                        <p className='font-POP text-center text-base font-light mt-[1vh] mx-[2vw]'>Financing for livestock inputs like feed, healthcare, and equipment. </p>
                                    </div>
                                </div>
                                <div className='sm:w-[80vw] sm:mb-[5vh] md:w-[25vw] md:mb-0'>
                                    <div>
                                        <img alt="credit" src="/fast-loan.png" className='w-[15vh] h-[15vh] sm:ml-[25vw] md:ml-[7vw] mb-[2vh]' />
                                    </div>
                                    <div>
                                        <p className='font-POP text-center text-2xl'>Fast & Flexible</p>
                                    </div>
                                    <div>
                                        <p className='font-POP text-center text-base font-light mt-[1vh] mx-[2vw]'>Quick loan approvals with tailored repayment options.</p>
                                    </div>
                                </div>
                                <div className='sm:w-[80vw] sm:mb-[5vh] md:w-[25vw] md:mb-0'>
                                    <div>
                                        <img alt="credit" src="/growth-protection.png" className='w-[15vh] h-[15vh] sm:ml-[25vw] md:ml-[7vw] mb-[2vh]' />
                                    </div>
                                    <div>
                                        <p className='font-POP text-center text-2xl'>Growth & Protection</p>
                                    </div>
                                    <div>
                                        <p className='font-POP text-center text-base font-light mt-[1vh] mx-[2vw]'>Support for scaling operations and insurance coverage.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='flex sm:flex-col md:flex-row'>
                                <div className='sm:w-[80vw] sm:mb-[5vh] md:w-[25vw] md:mb-0'>
                                    <div>
                                        <img alt="credit" src="/RT-escrow.png" className='w-[15vh] h-[15vh] sm:ml-[25vw] md:ml-[7vw] mb-[2vh]' />
                                    </div>
                                    <div>
                                        <p className='font-POP text-center text-2xl'>Real-Time Payments</p>
                                    </div>
                                    <div>
                                        <p className='font-POP text-center text-base font-light mt-[1vh] mx-[2vw]'>Escrow-based collections for instant payouts post-loan deductions.</p>
                                    </div>
                                </div>
                                <div className='sm:w-[80vw] sm:mb-[5vh] md:w-[25vw] md:mb-0'>
                                    <div>
                                        <img alt="credit" src="/market-udyam.png" className='w-[15vh] h-[15vh] sm:ml-[25vw] md:ml-[7vw] mb-[2vh]' />
                                    </div>
                                    <div>
                                        <p className='font-POP text-center text-2xl'>Market & Pricing</p>
                                    </div>
                                    <div>
                                        <p className='font-POP text-center text-base font-light mt-[1vh] mx-[2vw]'>Wider market access with transparent, fair pricing.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='mb-[5vh]'>
                        <div className='mt-20 sm:mx-10 md:mx-0'>
                            <p className='text-center text-gray-600 font-POP mb-5'>why Ample Udyam?</p>
                            <p className='text-center text-3xl font-semibold font-POP'>Providing best financial solutions for growth and stability.</p>
                        </div>
                        <div className='flex sm:flex-col md:flex-row'>
                            <div className='flex flex-col sm:w-[90vw] md:w-[25vw] sm:ml-[5vw] md:ml-[8vw] mt-20'>
                                <img className='w-10' alt="flexible" src="/icon-flex.jpg" />
                                <p className='mt-5 font-POP'>Improved Access to Credit and Financing</p>
                                <p className='mt-5'>Simplifies obtaining credit for livestock inputs, offering tailored loans with fast approvals and flexible repayment options, enabling farmers to manage their financial needs efficiently.</p>
                            </div>
                            <div className='flex flex-col sm:w-[90vw] md:w-[25vw] sm:ml-[5vw] md:ml-[8vw] mt-20'>
                                <img className='w-10' alt="flexible" src="/onboarding.png" />
                                <p className='mt-5 font-POP'>Optimized Cash Flow and Receivables Management</p>
                                <p className='mt-5'>Ensures steady cash flow through real-time payment systems, such as escrow-based collections, and supports efficient receivables tracking to reduce financial stress for farmers.</p>
                            </div>
                            <div className='flex flex-col sm:w-[90vw] md:w-[25vw] sm:ml-[5vw] md:ml-[8vw] mt-20'>
                                <img className='w-10' alt="flexible" src="/inputs.png" />
                                <p className='mt-5 font-POP'>Support for Growth Financing</p>
                                <p className='mt-5'>Provides funding for scaling operations,or expanding capabilities, empowering farmers to grow their businesses sustainably.</p>
                            </div>
                        </div>
                        <div className='flex sm:flex-col md:flex-row'>
                            <div className='flex flex-col sm:w-[90vw] md:w-[25vw] sm:ml-[5vw] md:ml-[8vw] mt-20'>
                                <img className='w-10' alt="flexible" src="/icon-train.jpg" />
                                <p className='mt-5 font-POP'>Market Access and Pricing Transparency</p>
                                <p className='mt-5'>Connects farmers to wider markets while ensuring fair pricing through transparent processes, helping them achieve maximum returns for their products.</p>
                            </div>
                            <div className='flex flex-col sm:w-[90vw] md:w-[25vw] sm:ml-[5vw] md:ml-[8vw] mt-20'>
                                <img className='w-10' alt="flexible" src="/icon-tech.jpg" />
                                <p className='mt-5 font-POP'>Risk Management and Insurance Solutions</p>
                                <p className='mt-5'>Offers insurance coverage to protect against uncertainties such as natural disasters or livestock health risks, securing the farmer's livelihood.</p>
                            </div>
                            <div className='flex flex-col sm:w-[90vw] md:w-[25vw] sm:ml-[5vw] md:ml-[8vw] mt-20'>
                                <img className='w-10' alt="flexible" src="/store.png" />
                                <p className='mt-5 font-POP'>Wealth Creation and Financial Stability</p>
                                <p className='mt-5'>Enables farmers to build sustainable wealth through improved profitability, strategic investments, and long-term financial planning, fostering economic growth and resilience.</p>
                            </div>
                        </div>
                    </div>
                    <div className='bg-[#f6f3ed] pt-10'>
                        <p className='text-center text-gray-600 font-POP mb-5'>How it works ?</p>
                        <p className='text-center text-3xl font-semibold font-POP'>Simple Process, Big Benefits</p>
                        <div className='md:flex mt-20 sm:ml-[1vw] sm:mr-[1vw] md:ml-[5vw] lg:ml-[7vw] sm:hidden'>
                            <div>
                                <div className='flex'>
                                    <div className='rounded-full sm:w-[10vh] sm:h-[10vh] lg:w-[15vh] lg:h-[15vh] bg-white'>
                                        <div>
                                            <img className='sm:w-[10vw] sm:pt-3 sm:ml-2 lg:w-[5vw] lg:ml-5 lg:pt-5' alt="app_download" src="/download.png" />
                                        </div>
                                    </div>
                                    <div>
                                        <img className='sm:w-5 lg:w-12 mt-[4vh] md:ml-[2vw] md:mr-[2vw] lg:ml-[4vw] lg:mr-[4vw]' alt="arrow" src="/next.png" />
                                    </div>
                                </div>
                                <div className='sm:mt-5 lg:mt-10 mb-10'>
                                    <p className='sm:text-sm sm:w-[17vw] md:text-xl'>Download the App</p>
                                </div>
                            </div>
                            <div>
                                <div className='flex'>
                                    <div className='rounded-full sm:w-[10vh] sm:h-[10vh] lg:w-[15vh] lg:h-[15vh] bg-white'>
                                        <div>
                                            <img className='sm:w-[10vw] sm:pt-3 sm:ml-2 lg:w-[5vw] lg:ml-5 lg:pt-5' alt="apply_loan" src="/loan.png" />
                                        </div>
                                    </div>
                                    <div>
                                        <img className='sm:w-5 lg:w-12 mt-[4vh] md:ml-[2vw] md:mr-[2vw] lg:ml-[4vw] lg:mr-[4vw]' alt="arrow" src="/next.png" />
                                    </div>
                                </div>
                                <div className='sm:mt-5 lg:mt-10 mb-10'>
                                    <p className='sm:text-sm sm:w-[17vw] md:text-xl'>Apply for Ample Udyam Loan</p>
                                </div>
                            </div>
                            <div>
                                <div className='flex'>
                                    <div className='rounded-full sm:w-[10vh] sm:h-[10vh] lg:w-[15vh] lg:h-[15vh] bg-white'>
                                        <div>
                                            <img className='sm:w-[10vw] sm:pt-3 sm:ml-2 lg:w-[5vw] lg:ml-5 lg:pt-5' alt="apply_loan" src="/retail-store.png" />
                                        </div>
                                    </div>
                                    <div>
                                        <img className='sm:w-5 lg:w-12 mt-[4vh] md:ml-[2vw] md:mr-[2vw] lg:ml-[4vw] lg:mr-[4vw]' alt="arrow" src="/next.png" />
                                    </div>
                                </div>
                                <div className='sm:mt-5 lg:mt-10 mb-10'>
                                    <p className='sm:text-sm sm:w-[17vw] md:text-xl'>Locate Partner Retailers</p>
                                </div>
                            </div>
                            <div>
                                <div className='flex'>
                                    <div className='rounded-full sm:w-[10vh] sm:h-[10vh] lg:w-[15vh] lg:h-[15vh] bg-white'>
                                        <div>
                                            <img className='sm:w-[10vw] sm:pt-3 sm:ml-2 lg:w-[5vw] lg:ml-5 lg:pt-5' alt="apply_loan" src="/hen.png" />
                                        </div>
                                    </div>
                                    <div>
                                        <img className='sm:w-5 lg:w-12 mt-[4vh] md:ml-[2vw] md:mr-[2vw] lg:ml-[4vw] lg:mr-[4vw]' alt="arrow" src="/next.png" />
                                    </div>
                                </div>
                                <div className='sm:mt-5 lg:mt-10 mb-10'>
                                    <p className='sm:text-sm sm:w-[17vw] md:text-xl'>Purchase your Inputs</p>
                                </div>
                            </div>
                            <div>
                                <div className='flex'>
                                    <div className='rounded-full sm:w-[10vh] sm:h-[10vh] lg:w-[15vh] lg:h-[15vh] bg-white'>
                                        <div>
                                            <img className='sm:w-[10vw] sm:pt-3 sm:ml-3 lg:w-[5vw] lg:ml-5 lg:pt-5' alt="apply_loan" src="/repay.png" />
                                        </div>
                                    </div>
                                </div>
                                <div className='sm:mt-5 lg:mt-10 mb-10'>
                                    <p className='sm:text-sm sm:w-[17vw] md:text-xl'>Repay Post-Harvest</p>
                                </div>
                            </div>
                        </div>
                        <div className='md:hidden'>
                            <div className='flex flex-col mt-[3vh]'>
                                <div className='w-[20vw] h-[20vw] bg-white rounded-full ml-[40vw]'>
                                    <img src="/download.png" alt="download" className='w-[12vw] h-[12vw] ml-[4vw] mt-[2vh]' />
                                </div>
                                <div>
                                    <p className='font-POP w-full text-base ml-[30vw] mt-[2vh]'>Downlaod the App</p>
                                </div>
                                <div>
                                    <img src="/down-arrow.png" alt="next" className='h-[5vh] ml-[46vw] mt-[3vh]' />
                                </div>
                            </div>
                            <div className='flex flex-col mt-[3vh]'>
                                <div className='w-[20vw] h-[20vw] bg-white rounded-full ml-[40vw]'>
                                    <img src="/loan.png" alt="download" className='w-[12vw] h-[12vw] ml-[4vw] mt-[2vh]' />
                                </div>
                                <div>
                                    <p className='font-POP w-full text-base ml-[20vw] mt-[2vh]'>Apply for a Ample Udyam Loan</p>
                                </div>
                                <div>
                                    <img src="/down-arrow.png" alt="next" className='h-[5vh] ml-[46vw] mt-[3vh]' />
                                </div>
                            </div>
                            <div className='flex flex-col mt-[3vh]'>
                                <div className='w-[20vw] h-[20vw] bg-white rounded-full ml-[40vw]'>
                                    <img src="/retail-store.png" alt="download" className='w-[12vw] h-[12vw] ml-[4vw] mt-[2vh]' />
                                </div>
                                <div>
                                    <p className='font-POP w-full text-base ml-[25vw] mt-[2vh]'>Locate Partner Retailers</p>
                                </div>
                                <div>
                                    <img src="/down-arrow.png" alt="next" className='h-[5vh] ml-[46vw] mt-[3vh]' />
                                </div>
                            </div>
                            <div className='flex flex-col mt-[3vh]'>
                                <div className='w-[20vw] h-[20vw] bg-white rounded-full ml-[40vw]'>
                                    <img src="/hen.png" alt="download" className='w-[12vw] h-[12vw] ml-[4vw] mt-[2vh]' />
                                </div>
                                <div>
                                    <p className='font-POP w-full text-base ml-[30vw] mt-[2vh]'>Purchase your Inputs</p>
                                </div>
                                <div>
                                    <img src="/down-arrow.png" alt="next" className='h-[5vh] ml-[46vw] mt-[3vh]' />
                                </div>
                            </div>
                            <div className='flex flex-col mt-[3vh] pb-[5vh]'>
                                <div className='w-[20vw] h-[20vw] bg-white rounded-full ml-[40vw]'>
                                    <img src="/repay.png" alt="download" className='w-[12vw] h-[12vw] ml-[4vw] mt-[2vh]' />
                                </div>
                                <div>
                                    <p className='font-POP w-full text-base ml-[30vw] mt-[2vh]'>Repay Post-Harvest</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div>
                        <div className='mt-[20vh]'>
                            <p className='text-center text-gray-600 font-POP mb-5'>Exploring opportunities with Ample Udyam?</p>
                            <p className='text-center text-3xl font-semibold font-POP'>Ensure you meet our expectations</p>
                        </div>
                        <div className='flex sm:ml-[2vw] sm:mr-[2vw] md:ml-[10vw] md:mr-[10vw] mt-20'>
                            <div className='w-[35vw] rounded-lg hover:border-[#f8cc48] hover:border-2 mr-[10vw]'>
                                <img className='ml-[10vw] mb-10 mt-5' alt="farm" src="/farm-own.webp" />
                                <div className='flex ml-[8vw] mb-10'>
                                    <img className='sm:w-5 sm:h-5 md:w-10 md:h-10' alt="tick" src="/tick.png" />
                                    <p className='md:mt-2 ml-2'>Own land and a farm.</p>
                                </div>
                            </div>
                            <div className='w-[35vw] rounded-lg hover:border-[#f8cc48] hover:border-2'>
                                <img className='ml-[10vw] mb-10 mt-5' alt="farm" src="/contract.webp" />
                                <div className='flex ml-[8vw] mb-10'>
                                    <img className='sm:w-5 sm:h-5 md:w-10 md:h-10' alt="tick" src="/tick.png" />
                                    <p className='md:mt-2 ml-2'>Ready to sign a contract.</p>
                                </div>
                            </div>
                        </div>
                        <div className='flex sm:ml-[2vw] sm:mr-[2vw] md:ml-[10vw] md:mr-[10vw] mt-20'>
                            <div className='w-[35vw] rounded-lg hover:border-[#f8cc48] hover:border-2 mr-[10vw]'>
                                <img className='ml-[10vw] mb-10 mt-5' alt="farm" src="/collateral.webp" />
                                <div className='flex ml-[8vw] mb-10'>
                                    <img className='sm:w-5 sm:h-5 md:w-10 md:h-10' alt="tick" src="/tick.png" />
                                    <p className='md:mt-2 ml-2'>Prepare collateral (securities).</p>
                                </div>
                            </div>
                            <div className='w-[35vw] rounded-lg hover:border-[#f8cc48] hover:border-2'>
                                <img className='ml-[10vw] mb-10 sm:w-[35vw] md:w-[15vw] lg:w-[10vw] mt-5' alt="farm" src="/service.png" />
                                <div className='flex ml-[8vw] mb-10'>
                                    <img className='sm:w-5 sm:h-5 md:w-10 md:h-10' alt="tick" src="/tick.png" />
                                    <p className='md:mt-2 ml-2'>Must be in Servicable Pincode</p>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div>
                        <div className='relative mt-[25vh]'>
                            <img alt="footerimage" src="/footer.png" />
                        </div>
                        <div className='bg-[#496E58] flex justify-between pb-12 sm:flex-col lg:flex-row'>
                            <div className='sm:ml-[10vw] lg:ml-20'>
                                <img className='mt-5 mb-5 md:ml-[20vw] lg:ml-0' alt="footer" src="/amplesavings_logo.png" />
                                <img className="sm:w-[40vw] lg:w-[15vw] sm:ml-[15vw] lg:ml-5" alt="comming-soon" src="/comingsoon.png" />
                            </div>
                            <div className='flex flex-col sm:items-center lg:items-start sm:mt-10 lg:mt-0'>
                                <p className='text-white text-2xl font-POP font-bold mb-5'>More Links</p>
                                <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description#Input_Financing">Input Financing</Link>
                                <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description#Payment_Receivables">Payment & Receivables</Link>
                                <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description#Insurance">Insurance</Link>
                                <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description#Farm">Farm Mechanization</Link>
                                <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description#Market">Market Linkage</Link>
                                <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description#Advisory">Advisory</Link>
                                <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description#wealth">Wealth</Link>
                            </div>
                            <div className='flex flex-col sm:items-center lg:items-start sm:mt-10 lg:mt-0'>
                                <p className='text-white text-2xl font-POP font-bold mb-5'>Pages</p>
                                <Link className='text-white text-xl font-POP mb-2 font-normal' to="/company">Company</Link>
                                <Link className='text-white text-xl font-POP mb-2 font-normal' to="/ampleudyam">Udyam</Link>
                                <Link className='text-white text-xl font-POP mb-2 font-normal' to="/amplewealth">Savings</Link>
                                <div className='text-white text-xl font-POP mb-2 font-normal cursor-pointer' onClick={handleModal}>
                                    {isContact === true ? <div><Conatctus onClose={handleModal} /></div> : <p>Contact Us</p>}
                                </div>
                            </div>
                            <div className='flex flex-col sm:mt-10 lg:mt-0 sm:mr-0 lg:mr-20 sm:items-center'>
                                <p className='text-white text-2xl font-POP font-bold mb-5'>Contact with us</p>
                                <div className='flex flex-row'>
                                    <a href='https://amplesavings.com'><img className='w-12 h-12 mr-1' alt="facebook" src="/facebook.png" /></a>
                                    <a href='https://amplesavings.com'><img className='w-12 h-12 mr-3' alt="instagram" src="/Instagram.png" /></a>
                                    <a href='https://amplesavings.com'><img className='w-10 h-10 mr-4 mt-1' alt="linkedin" src="/linkedin.png" /></a>
                                    <a href='https://amplesavings.com'><img className='w-10 h-10 mr-3 mt-1' alt="twitter" src="/twitter.png" /></a>
                                </div>
                                <div className='flex mt-5'>
                                    <img className='w-10 mr-2' src="/email.png" alt="mail icon" />
                                    <Link className='text-white text-lg font-POP mt-1 font-normal' to="#" onClick={(e) => {
                                        window.location.href = "careers@amplesavings.com";
                                        e.preventDefault();
                                    }}>careers@amplesavings.com</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> :
                <div>
                    <div onClick={toggleMenu}>
                        <div className='flex w-full justify-between'>
                            <div className='flex ml-7 mt-5'>
                                <Link to="/"><img className='w-[30vw]' alt="logo" src="/logo_c.png" /></Link>
                            </div>
                            <div className='mt-7'>
                                <img className='w-[7vw] mr-10' alt="links" src="/close.png" />
                            </div>
                        </div>
                    </div>
                    <div>
                        <Link to="/company"><p className='mt-20 text-center font-POP text-xl'>Company</p></Link>
                        <Link to="/ampleudyam"><p className='mt-5 text-center font-POP text-xl'>Udyam</p></Link>
                        <Link to="/amplewealth"><p className='mt-5 text-center font-POP text-xl'>Savings</p></Link>
                    </div>
                </div>
            }
        </div>
    )
}

export default Ampleudyam