import { useState } from 'react';
import { Link } from 'react-router-dom';
import Conatctus from './Conatctus';

export default function Description() {
  const [isOpen, setIsOpen] = useState(false)
  const [isContact, setIsContact] = useState(false)
  const toggleMenu = () => {
    setIsOpen((open) => !open)
  }
  const handleModal = () => {
    console.log(isContact);
    setIsContact((open) => !open); // Update parent state to close modal
    console.log(isContact);
  };
  return (
    <div>
      {isOpen === false ?
        <div>
          <div className='flex top-5 w-full justify-between sm:mt-[3vh] lg:mt-0'>
            <div className='flex md:ml-10 sm:ml-7'>
              <Link to="/"><img className='sm:w-[30vw] md:w-[20vw] lg:w-[15vw]' alt="logo" src="/logo_c.png" /></Link>
            </div>
            <div className='flex md:mt-2 lg:mt-5 md:flex md:items-center md:mr-8 lg:mr-16 sm:hidden'>
              <Link className='md:mr-2 md:text-lg lg:mr-10 lg:text-xl font-POP font-semibold' to="/company">Company</Link>
              <Link className='md:mr-2 md:text-lg lg:mr-10 lg:text-xl font-POP font-semibold' to="/ampleudyam">Udyam</Link>
              <Link className='md:mr-2 md:text-lg lg:mr-10 lg:text-xl font-POP font-semibold' to="/amplewealth">Savings</Link>
            </div>
            <div className='sm:flex sm:items-end md:hidden' onClick={toggleMenu}>
              <img className='sm:w-[10vw] mr-5' alt="links" src="/menu.png" />
            </div>
          </div>
          <div className='sm:ml-[3vw] lg:ml-0'>
            <div id="Input_Financing" className='sm:mb-10 lg:mb-0 sm:mt-[10vh] lg:mt-[10vh] flex justify-between sm:flex-col lg:flex-row lg:w-[90vw] lg:ml-[4vw]'>
              <div className='sm:w-[80vw] sm:text-start sm:ml-7 sm:mr-[2vw] lg:ml-0 lg:text-start lg:w-[50vw] lg:mr-[2vw]'>
                <p className='sm:text-2xl md:text-4xl font-POP font-medium'>Input Financing</p>
                <p className='md:text-xl lg:text-2xl font-POP mt-3 text-[#0f4022]'>Empowering Livestock Farmers with Quality Inputs and Technology</p>
                <p className='mt-3 text-slate-500 sm:text-lg md:text-xl'>We provide livestock farmers with essential, quality inputs—including healthy newborns, premium feed, precise medication, and comprehensive insurance—ensuring timely delivery at the best prices. Our advanced supply chain solutions drive direct cost savings of up to 15% and can increase farmers' earnings by up to 4x. Through expert post-sales support, real-time market linkage, and continuous livestock health monitoring, we empower farmers to maximize productivity and income, promoting sustainable practices in livestock farming.</p>
              </div>
              <div className='sm:w-[80vw] sm:mt-5 sm:ml-[5vw] md:ml-[3vw] lg:mt-0 lg:ml-0 lg:mr-5 lg:flex lg:items-center lg:w-[30vw]'>
                <img className='rounded-lg' src='/Input_finance.png' alt='input_finance' />
              </div>
            </div>
            <div id="Payment_Receivables" className='sm:mb-10 lg:mb-0 sm:mt-[10vh] lg:mt-[10vh] flex justify-between sm:flex-col lg:flex-row lg:w-[90vw] lg:ml-[4vw]'>
              <div className='sm:w-[80vw] sm:mb-10 sm:mt-5 sm:ml-[5vw] md:ml-[3vw] lg:mb-0 lg:mt-0 lg:ml-0 lg:mr-5 lg:flex lg:items-center lg:w-[30vw]'>
                <img className='rounded-lg' src='/payment_receivables.png' alt='receivables' />
              </div>
              <div className='sm:w-[80vw] sm:text-start sm:ml-7 sm:mr-[2vw] lg:ml-0 lg:text-start lg:w-[50vw] lg:mr-[2vw]'>
                <p className='sm:text-2xl md:text-4xl font-POP font-medium'>Payment & Receivables</p>
                <p className='md:text-xl lg:text-2xl font-POP mt-3 text-[#0f4022]'>Optimizing Cash Flow and Receivables for Seamless Farming</p>
                <p className='mt-3 text-slate-500 sm:text-lg md:text-xl'>We are transforming farming by automating cash flow and receivables management. With streamlined payment collections, real-time tracking, and timely disbursements, farmers can ensure steady cash flow, reduce manual efforts, and minimize delays. Our technology empowers you to focus on growing your business while we manage your payment processes seamlessly.</p>
              </div>
            </div>
            <div id="Insurance" className='sm:mb-10 lg:mb-0 sm:mt-[10vh] lg:mt-[10vh] flex justify-between sm:flex-col lg:flex-row lg:w-[90vw] lg:ml-[4vw]'>
              <div className='sm:w-[80vw] sm:text-start sm:ml-7 sm:mr-[2vw] lg:ml-0 lg:text-start lg:w-[50vw] lg:mr-[2vw]'>
                <p className='sm:text-2xl md:text-4xl font-POP font-medium'>Insurance</p>
                <p className='md:text-xl lg:text-2xl font-POP mt-3 text-[#0f4022]'>Ensuring Livestock Farmers' Security with Insurance Benefits</p>
                <p className='mt-3 text-slate-500 sm:text-lg md:text-xl'>Financial protection covers losses from illness, accidents, and natural disasters. Income stability ensures compensation for animal loss, helping farmers recover from setbacks. Farmers can avail these benefits through affordable, subsidized insurance plans offered by Ample Savings, providing a reliable safety net and fostering resilience in challenging times.</p>
              </div>
              <div className='sm:w-[80vw] sm:mt-5 sm:ml-[5vw] md:ml-[3vw] lg:mt-0 lg:ml-0 lg:mr-5 lg:flex lg:items-center lg:w-[30vw]'>
                <img className='rounded-lg' src='/farm_insurance.png' alt='finance' />
              </div>
            </div>
            <div id="Farm" className='sm:mb-10 lg:mb-0 sm:mt-[10vh] lg:mt-[10vh] flex justify-between sm:flex-col lg:flex-row lg:w-[90vw] lg:ml-[4vw]'>
              <div className='sm:w-[80vw] sm:mb-10 sm:mt-5 sm:ml-[5vw] md:ml-[3vw] lg:mb-0 lg:mt-0 lg:ml-0 lg:mr-5 lg:flex lg:items-center lg:w-[30vw]'>
                <img className='rounded-lg' src='/farm_intelligence.png' alt='finance' />
              </div>
              <div className='sm:w-[80vw] sm:text-start sm:ml-7 sm:mr-[2vw] lg:ml-0 lg:text-start lg:w-[50vw] lg:mr-[2vw]'>
                <p className='sm:text-2xl md:text-4xl font-POP font-medium'>Farm Mechanization</p>
                <p className='md:text-xl lg:text-2xl font-POP mt-3 text-[#0f4022]'>Financing Modern Solutions to Power Your Farming Growth.</p>
                <p className='mt-3 text-slate-500 sm:text-lg md:text-xl'>Avail a Farm Mechanisation Loan to upgrade your livestock operations with modern equipments.Boost productivity, reduce labor, and improve product quality with flexible financing options, competitive interest rates, and tailored repayment plans designed to meet your farming needs.</p>
              </div>
            </div>
            <div id="Market" className='sm:mb-10 lg:mb-0 sm:mt-[10vh] lg:mt-[10vh] flex justify-between sm:flex-col lg:flex-row lg:w-[90vw] lg:ml-[4vw]'>
              <div className='sm:w-[80vw] sm:text-start sm:ml-7 sm:mr-[2vw] lg:ml-0 lg:text-start lg:w-[50vw] lg:mr-[2vw]'>
                <p className='sm:text-2xl md:text-4xl font-POP font-medium'>Direct Market Linkage</p>
                <p className='md:text-xl lg:text-2xl font-POP mt-3 text-[#0f4022]'>Empowering Livestock Farmers with Fair Access</p>
                <p className='mt-3 text-slate-500 sm:text-lg md:text-xl'>Direct market linkage connects livestock farmers straight to buyers, eliminating middlemen and ensuring they receive fair prices for their products. This model not only boosts farmers' income but also builds a transparent supply chain that benefits all parties involved.</p>
                <p className='mt-3 text-slate-500 sm:text-lg md:text-xl'>Ample Savings connects livestock farmers directly with buyers, ensuring fair prices and eliminating middlemen. This boosts farmers' income, offers real-time price transparency, ensures stable demand and supply, and enhances quality control through buyer feedback. Additionally, direct transactions via Ample Savings ensure timely, secure payments, improving cash flow and fostering trust.</p>
              </div>
              <div className='sm:w-[80vw] sm:mt-5 sm:ml-[5vw] md:ml-[3vw] lg:mt-0 lg:ml-0 lg:mr-5 lg:flex lg:items-center lg:w-[30vw]'>
                <img className='rounded-lg' src='/market_link.png' alt='finance' />
              </div>
            </div>
            <div id="Advisory" className='sm:top-[15vh] lg:mt-[10vh] flex justify-between sm:flex-col lg:flex-row lg:w-[90vw] lg:ml-[4vw]'>
              <div className='sm:w-[80vw] sm:mb-10 sm:mt-5 sm:ml-[5vw] md:ml-[3vw] lg:mb-0 lg:mt-0 lg:ml-0 lg:mr-5 lg:flex lg:items-center lg:w-[30vw]'>
                <img className='rounded-lg' src='/advisory.png' alt='finance' />
              </div>
              <div className='sm:w-[80vw] sm:text-start sm:ml-7 sm:mr-[2vw] lg:ml-0 lg:text-start lg:w-[50vw] lg:mr-[2vw]'>
                <p className='sm:text-2xl md:text-4xl font-POP font-medium'>Advisory</p>
                <p className='md:text-xl lg:text-2xl font-POP mt-3 text-[#0f4022]'>Key Practices for Optimizing Livestock Health and Profitability</p>
                <p className='mt-3 text-slate-500 sm:text-lg md:text-xl'>Ample Savings connects livestock farmers to expert advisors who provide guidance on health and nutrition, balanced feed, and regular vaccinations. They ensure quality breeding, newborn care, proper housing, preventive medication, and market-driven practices. This holistic support helps farmers optimize livestock health, maximize profits, and maintain sustainable farming practices.</p>
              </div>
            </div>
            <div id="wealth" className='sm:mb-10 lg:mb-0 sm:mt-[10vh] lg:mt-[10vh] flex justify-between sm:flex-col lg:flex-row lg:w-[90vw] lg:ml-[4vw]'>
              <div className='sm:w-[80vw] sm:text-start sm:ml-7 sm:mr-[2vw] lg:ml-0 lg:text-start lg:w-[50vw] lg:mr-[2vw]'>
                <p className='sm:text-2xl md:text-4xl font-POP font-medium'>Wealth Creation</p>
                <p className='md:text-xl lg:text-2xl font-POP mt-3 text-[#0f4022]'>Securing Your Future with Smart Financial Solutions for Tomorrow's Needs.</p>
                <p className='mt-3 text-slate-500 sm:text-lg md:text-xl'>Create your wealth with Ample Savings through Fixed Deposits and Recurring Deposits. Enjoy assured returns, disciplined savings, and higher interest rates. Secure your financial future, fund your livestock farming needs, and achieve long-term stability with tailored solutions designed to empower your growth.</p>
              </div>
              <div className='sm:w-[80vw] sm:mt-5 sm:ml-[5vw] md:ml-[3vw] lg:mt-0 lg:ml-0 lg:mr-5 lg:flex lg:items-center lg:w-[30vw]'>
                <img className='rounded-lg' src='/wealth_creation.png' alt='finance' />
              </div>
            </div>
          </div>
          <div className='relative mt-[25vh]'>
            <img alt="footerimage" src="/footer.png" />
          </div>
          <div className='bg-[#496E58] flex justify-between pb-12 sm:flex-col lg:flex-row'>
            <div className='sm:ml-[10vw] lg:ml-20'>
              <img className='mt-5 mb-5 md:ml-[20vw] lg:ml-0' alt="footer" src="/amplesavings_logo.png" />
              <img className="sm:w-[40vw] lg:w-[15vw] sm:ml-[15vw] lg:ml-5" alt="comming-soon" src="/comingsoon.png" />
            </div>
            <div className='flex flex-col sm:items-center lg:items-start sm:mt-10 lg:mt-0'>
              <p className='text-white text-2xl font-POP font-bold mb-5'>More Links</p>
              <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description#Input_Financing">Input Financing</Link>
              <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description#Payment_Receivables">Payment & Receivables</Link>
              <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description#Insurance">Insurance</Link>
              <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description#Farm">Farm Mechanization</Link>
              <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description#Market">Market Linkage</Link>
              <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description#Advisory">Advisory</Link>
              <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description#wealth">Wealth</Link>
            </div>
            <div className='flex flex-col sm:items-center lg:items-start sm:mt-10 lg:mt-0'>
              <p className='text-white text-2xl font-POP font-bold mb-5'>Pages</p>
              <Link className='text-white text-xl font-POP mb-2 font-normal' to="/company">Company</Link>
              <Link className='text-white text-xl font-POP mb-2 font-normal' to="/ampleudyam">Udyam</Link>
              <Link className='text-white text-xl font-POP mb-2 font-normal' to="/amplewealth">Savings</Link>
              <div className='text-white text-xl font-POP mb-2 font-normal cursor-pointer' onClick={handleModal}>
                {isContact === true ? <div><Conatctus onClose={handleModal} /></div> : <p>Contact Us</p>}

              </div>
            </div>
            <div className='flex flex-col sm:mt-10 lg:mt-0 sm:mr-0 lg:mr-20 sm:items-center'>
              <p className='text-white text-2xl font-POP font-bold mb-5'>Contact with us</p>
              <div className='flex flex-row'>
                <a href='https://amplesavings.com'><img className='w-12 h-12 mr-1' alt="facebook" src="/facebook.png" /></a>
                <a href='https://amplesavings.com'><img className='w-12 h-12 mr-3' alt="instagram" src="/Instagram.png" /></a>
                <a href='https://www.linkedin.com/company/amplesavings/'><img className='w-10 h-10 mr-4 mt-1' alt="linkedin" src="/linkedin.png" /></a>
                <a href='https://amplesavings.com'><img className='w-10 h-10 mr-3 mt-1' alt="twitter" src="/twitter.png" /></a>
              </div>
              <div className='flex mt-5'>
                <img className='w-10 mr-2' src="/email.png" alt="mail icon" />
                <Link className='text-white text-lg font-POP mt-1 font-normal' to="#" onClick={(e) => {
                  window.location.href = "careers@amplesavings.com";
                  e.preventDefault();
                }}>careers@amplesavings.com</Link>
              </div>
            </div>
          </div>
        </div> :
        <div>
          <div onClick={toggleMenu}>
            <div className='flex w-full justify-between'>
              <div className='flex ml-7 mt-5'>
                <Link to="/"><img className='w-[30vw]' alt="logo" src="/logo_c.png" /></Link>
              </div>
              <div className='mt-7'>
                <img className='w-[7vw] mr-10' alt="links" src="/close.png" />
              </div>
            </div>
          </div>
          <div>
            <Link to="/company"><p className='mt-20 text-center font-POP text-xl'>Company</p></Link>
            <Link to="/ampleudyam"><p className='mt-5 text-center font-POP text-xl'>Udyam</p></Link>
            <Link to="/amplewealth"><p className='mt-5 text-center font-POP text-xl'>Savings</p></Link>
          </div>
        </div>
      }
    </div>
  )
}