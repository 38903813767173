import { Link } from 'react-router-dom';
import Carousal from './carousal.component';
import { useState } from 'react';

export default function Home() {
    const [isOpen, setIsOpen] = useState(false);
    const toggleMenu = () => {
        setIsOpen((open) => !open)
    }
    let slides = [
        "/try1.png",
        "/try2.png",
        "/try3.png",
        "/try4.png"
    ];
    let slides_mbl = [
        "/try1_mbl.png",
        "/try2_mbl.png",
        "/try3_mbl.png",
        "/try4_mbl.png"
    ];
    let slides_tab = [
        "/try1_tab.png",
        "/try2_tab.png",
        "/try3_tab.png",
        "/try4_tab.png"
    ];
    return (
        < div className='w-[100%] h-[100%] relative overflow-hidden'>
            {isOpen === false ?
                <div>
                    <div className='lg:flex sm:hidden md:hidden'>
                        <Carousal slides={slides} />
                    </div>
                    <div className='md:flex sm:hidden lg:hidden'>
                        <Carousal slides={slides_tab} />
                    </div>
                    <div className='sm:flex md:hidden lg:hidden'>
                        <Carousal slides={slides_mbl} />
                    </div>
                    <div className='absolute flex top-5 left-10 w-full justify-between'>
                        <div className='flex'>
                            <Link to="/"><img className='sm:w-[30vw] md:w-[15vw]' alt="logo" src="/amplesavings_logo-white.png" /></Link>
                        </div>
                        <div className='md:flex md:items-center md:mr-16 sm:hidden'>
                            <Link className='mr-10 text-white text-xl font-POP font-semibold' to="/company">Company</Link>
                            <Link className='mr-10 text-white text-xl font-POP font-semibold' to="/ampleudyam">Udyam</Link>
                            <Link className='mr-10 text-white text-xl font-POP font-semibold' to="/amplewealth">Savings</Link>
                        </div>
                        <div className='sm:flex sm:items-end md:hidden' onClick={toggleMenu}>
                            <img className='sm:w-[10vw] mr-20' alt="links" src="/menu.png" />
                        </div>
                    </div>
                </div> :
                <div>
                    <div onClick={toggleMenu}>
                        <div className='flex w-full justify-between'>
                            <div className='flex ml-7 mt-5'>
                                <Link to="/"><img className='w-[30vw]' alt="logo" src="/logo_c.png" /></Link>
                            </div>
                            <div className='mt-7'>
                                <img className='w-[7vw] mr-10' alt="links" src="/close.png" />
                            </div>
                        </div>
                    </div>
                    <div>
                        <Link to="/company"><p className='mt-20 text-center font-POP text-xl'>Company</p></Link>
                        <Link to="/ampleudyam"><p className='mt-5 text-center font-POP text-xl'>Udyam</p></Link>
                        <Link to="/amplewealth"><p className='mt-5 text-center font-POP text-xl'>Savings</p></Link>
                    </div>
                </div>
            }

        </div >
    )
}