import React, { useState } from 'react'
import validator from "validator";

function Conatctus({ onClose }) {
    const [success, setSuccess] = useState(false)
    const [placeholderEmail, setPlaceholderEmail] = useState("name@email.com");
    const [placeholderMobile, setPlaceholderMobile] = useState("Mobile Number");
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [des, setDes] = useState("")

    const validateEmail = () => {
        if (validator.isEmail(email)) {
            setPlaceholderEmail(email);
        } else {
            setPlaceholderEmail("Invalid Email");
            setEmail('')
        }
    }
    const validatePhone = () => {
        let isnum = /^\d+$/.test(phone);
        if (isnum && phone.length === 10) {
            setPlaceholderMobile(phone);
        } else {
            setPlaceholderMobile("Invalid Phone Number");
            setPhone('')
        }
    }
    const submit = () => {
        validateEmail()
        validatePhone()
        if (phone === placeholderMobile && email === placeholderEmail) {
            setSuccess(true)
        }
    }
    return (
        <div className='fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex items-center justify-center'>
            {success === false ?
                <div onClick={onClose} className='bg-white sm:w-[80vw] sm:h-[92vh] md:w-[60vw] md:h-[40vh] lg:w-[50vw] lg:h-[50vh] rounded-3xl'>
                    <div className='md:px-[5vw] lg:px-[2vw]'>
                        <div className='flex mt-[5vh] sm:ml-[5vw] md:ml-0'>
                            <p className='text-black text-POP font-bold text-3xl mr-2'>Get in </p>
                            <p className='text-[#0f4022] text-POP font-bold text-3xl'>Touch</p>
                        </div>
                        <hr className='bg-[#0f4022] sm:w-[60vw] md:w-[20vw] h-[0.2vw] mt-[1vh] sm:ml-[5vw] md:ml-0' />
                        <div className='sm:px-[5vw] md:px-0 '>
                            <p className='text-black text-sm mt-[2vh]'>We at Ample Savings are here to help! Reach out with your questions, feedback, or concerns, and our team will get back to you promptly.</p>
                        </div>
                        <div className='mt-[2vh] flex sm:flex-col sm:px-[5vw] md:px-0 md:flex-row'>
                            <div className="mb-5 mr-[1vw]">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Name</label>
                                <input type="text" value={name} id="name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="John Doe" onChange={(e) => setName(e.target.value)} required />
                            </div>
                            <div className="mb-5 mr-[1vw]">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                                <input type="email" value={email} id="email" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={placeholderEmail} onChange={(e) => setEmail(e.target.value)} required />
                            </div>
                            <div className="mb-5">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Mobile Number</label>
                                <input type="phone" value={phone} id="phone" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={placeholderMobile} onChange={(e) => setPhone(e.target.value)} required />
                            </div>
                        </div>
                        <div className='mt-[1vh] flex sm:flex-col sm:px-[5vw] md:px-0 md:flex-row'>
                            <div className="mb-5">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Description</label>
                                <textarea type="text" value={des} id="description" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block sm:w-[70vw] md:w-[30vw] p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Describe your requirement." onChange={(e) => setDes(e.target.value)} required />
                            </div>
                            <div className='md:mt-[3vh] lg:mt-[5vh] sm:ml-[20vw] md:ml-[5vw] sm:mb-[2vh] md:mb-0'>
                                <button onClick={submit} type="submit" className="text-white bg-[#0f4022] px-[10px] md:h-[4vh] lg:h-[5vh] rounded-lg">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div onClick={onClose} className='bg-white sm:w-[80vw] sm:h-[92vh] md:w-[60vw] md:h-[40vh] lg:w-[50vw] lg:h-[50vh] rounded-3xl flex flex-col items-center'>
                    <img className='sm:h-[40vh] sm:w-[60vw] sm:mt-[10vh] md:h-[20vh] md:w-[25vw] md:mt-[4vh] lg:h-[30vh] lg:w-[15vw] lg:mt-[3vh]' alt="success" src="/success.png" />
                    <div>
                        <p className='font-bold text-2xl text-black'>Thank You</p>
                    </div>
                    <div>
                        <p className='font-POP text-black text-base mt-[5vh]'>We will be in touch with you shortly!.</p>
                    </div>
                </div>
            }
        </div>
    )
}

export default Conatctus