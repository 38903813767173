import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import Conatctus from './Conatctus';

function Amplewealth() {
    const [isOpen, setIsOpen] = useState(false)
    const [isContact, setIsContact] = useState(false)
    const toggleMenu = () => {
        setIsOpen((open) => !open)
    }
    const handleModal = () => {
        console.log(isContact);
        setIsContact((open) => !open); // Update parent state to close modal
        console.log(isContact);
    };
    return (
        <div>
            {isOpen === false ?
                <div>
                    <div className='flex top-5 w-full justify-between sm:mt-[3vh] lg:mt-0'>
                        <div className='flex md:ml-10 sm:ml-7'>
                            <Link to="/"><img className='sm:w-[30vw] md:w-[20vw] lg:w-[15vw]' alt="logo" src="/logo_c.png" /></Link>
                        </div>
                        <div className='flex md:mt-2 lg:mt-5 md:flex md:items-center md:mr-8 lg:mr-16 sm:hidden'>
                            <Link className='md:mr-2 md:text-lg lg:mr-10 lg:text-xl font-POP font-semibold' to="/company">Company</Link>
                            <Link className='md:mr-2 md:text-lg lg:mr-10 lg:text-xl font-POP font-semibold' to="/ampleudyam">Udyam</Link>
                            <Link className='md:mr-2 md:text-lg lg:mr-10 lg:text-xl font-POP font-semibold' to="/amplewealth">Savings</Link>
                        </div>
                        <div className='sm:flex sm:items-end md:hidden' onClick={toggleMenu}>
                            <img className='sm:w-[10vw] mr-5' alt="links" src="/menu.png" />
                        </div>
                    </div>
                    <div className='mt-[20vh] mb-20'>
                        <p className='font-POP text-3xl font-bold text-center mb-16'>Ample Savings</p>
                        <p className='ml-[10vw] mr-[10vw] text-center font-POP'>With Ample Savings your deposit can help you in financial planning, emergency funds, and loan collateral.Overdrafts on deposits can provide security during emergencies, and financial inclusion which builds credit scores, unlocking better opportunities.With Save now, build later by investing in high-interest Recurring Deposits you can fund to start your rural business ventures.</p>
                    </div>
                    <div className='lg:hidden'>
                        <p className='text-center text-gray-600 font-POP mb-5 text-3xl font-semibold'>How it works?</p>
                        <div className='flex flex-col mt-[3vh]'>
                            <div className='w-[20vw] h-[20vw] bg-sky-200 rounded-full ml-[40vw]'>
                                <img src="/download.png" alt="download" className='w-[12vw] h-[12vw] ml-[4vw] mt-[3vh]' />
                            </div>
                            <div>
                                <p className='font-POP w-full text-xl font-semibold text-center mt-[2vh]'>Downlaod App</p>
                            </div>
                            <div>
                                <p className='font-POP text-base text-center mt-[2vh] mx-[15vw]'>Register on our app with your details and complete the KYC process for account activation. </p>
                            </div>
                            <div>
                                <img src="/down-arrow.png" alt="next" className='h-[5vh] ml-[46vw] mt-[3vh]' />
                            </div>
                        </div>
                        <div className='flex flex-col mt-[3vh]'>
                            <div className='w-[20vw] h-[20vw] bg-sky-200 rounded-full ml-[40vw]'>
                                <img src="/money-plan.png" alt="download" className='w-[12vw] h-[12vw] ml-[4vw] mt-[3vh]' />
                            </div>
                            <div>
                                <p className='font-POP w-full text-xl font-semibold text-center mt-[2vh]'>Choose Your Deposit Plan</p>
                            </div>
                            <div>
                                <p className='font-POP text-base text-center mt-[2vh] mx-[15vw]'>Select between a Fixed Deposit (FD) or Recurring Deposit (RD) based on your savings goals, preferred tenure and choice of bank.</p>
                            </div>
                            <div>
                                <img src="/down-arrow.png" alt="next" className='h-[5vh] ml-[46vw] mt-[3vh]' />
                            </div>
                        </div>
                        <div className='flex flex-col mt-[3vh]'>
                            <div className='w-[20vw] h-[20vw] bg-sky-200 rounded-full ml-[40vw]'>
                                <img src="/deposit.png" alt="download" className='w-[12vw] h-[12vw] ml-[4vw] mt-[3vh]' />
                            </div>
                            <div>
                                <p className='font-POP w-full text-xl font-semibold text-center mt-[2vh]'>Set Up and Fund Your Deposit</p>
                            </div>
                            <div>
                                <p className='font-POP text-base text-center mt-[2vh] mx-[15vw]'>Specify the amount and duration of your FD/RD and transfer funds to initiate your deposit.</p>
                            </div>
                            <div>
                                <img src="/down-arrow.png" alt="next" className='h-[5vh] ml-[46vw] mt-[3vh]' />
                            </div>
                        </div>
                        <div className='flex flex-col mt-[3vh]'>
                            <div className='w-[20vw] h-[20vw] bg-sky-200 rounded-full ml-[40vw]'>
                                <img src="/money-bag.png" alt="download" className='w-[12vw] h-[12vw] ml-[4vw] mt-[3vh]' />
                            </div>
                            <div>
                                <p className='font-POP w-full text-xl font-semibold text-center mt-[2vh]'>Activate Overdraft Facility</p>
                            </div>
                            <div>
                                <p className='font-POP text-base text-center mt-[2vh] mx-[15vw]'>Opt for the overdraft feature linked to your deposit to access instant funds during emergencies. </p>
                            </div>
                            <div>
                                <img src="/down-arrow.png" alt="next" className='h-[5vh] ml-[46vw] mt-[3vh]' />
                            </div>
                        </div>
                        <div className='flex flex-col mt-[3vh] pb-[5vh]'>
                            <div className='w-[20vw] h-[20vw] bg-sky-200 rounded-full ml-[40vw]'>
                                <img src="/profile.png" alt="download" className='w-[12vw] h-[12vw] ml-[4vw] mt-[3vh]' />
                            </div>
                            <div>
                                <p className='font-POP w-full text-xl font-semibold text-center mt-[2vh]'>Manage and Track</p>
                            </div>
                            <div>
                                <p className='font-POP text-base text-center mt-[2vh] mx-[15vw]'>Monitor your deposit growth and overdraft usage through the app, ensuring financial security and flexibility.</p>
                            </div>
                        </div>
                    </div>
                    <div className='sm:hidden lg:flex lg:flex-col'>
                        <p className='text-center text-gray-600 font-POP text-3xl font-semibold mt-[5vh]'>How it works?</p>
                        <div className='flex flex-row mx-[2vw] my-[10vh]'>
                            <div className='flex'>
                                <div className='flex flex-col'>
                                    <div className='w-[10vw] h-[10vw] bg-sky-200 rounded-full mb-[5vh] ml-[3vw]'>
                                        <img alt="download" src="/download.png" className='w-[6vw] h-[6vw] mt-[5vh] ml-[2vw]' />
                                    </div>
                                    <div className='mb-[2vh]'><p className='text-center'>Downlaod App</p></div>
                                    <div><p className='w-[15vw] text-center'>Register on our app with your details and complete the KYC process for account activation. </p></div>
                                </div>
                                <div><img alt="next" src="/next.png" className='w-[4vw] mt-[6vh] mr-[2vw]' /></div>
                            </div>
                            <div className='flex'>
                                <div className='flex flex-col'>
                                    <div className='w-[10vw] h-[10vw] bg-sky-200 rounded-full mb-[5vh] ml-[3vw]'>
                                        <img alt="download" src="/money-plan.png" className='w-[6vw] h-[6vw] mt-[5vh] ml-[2vw]' />
                                    </div>
                                    <div className='mb-[2vh]'><p className='text-center'>Choose Your Deposit Plan</p></div>
                                    <div><p className='w-[15vw] text-center'>Select between a Fixed Deposit (FD) or Recurring Deposit (RD) based on your savings goals, preferred tenure and choice of bank.</p></div>
                                </div>
                                <div><img alt="next" src="/next.png" className='w-[4vw] mt-[6vh] mr-[2vw]' /></div>
                            </div>
                            <div className='flex'>
                                <div className='flex flex-col'>
                                    <div className='w-[10vw] h-[10vw] bg-sky-200 rounded-full mb-[5vh] ml-[3vw]'>
                                        <img alt="download" src="/deposit.png" className='w-[6vw] h-[6vw] mt-[5vh] ml-[2vw]' />
                                    </div>
                                    <div className='mb-[2vh]'><p className='text-center'>Set Up and Fund Your Deposit</p></div>
                                    <div><p className='w-[15vw] text-center'>Specify the amount and duration of your FD/RD and transfer funds to initiate your deposit.</p></div>
                                </div>
                                <div><img alt="next" src="/next.png" className='w-[4vw] mt-[6vh] mr-[2vw]' /></div>
                            </div>
                            <div className='flex'>
                                <div className='flex flex-col'>
                                    <div className='w-[10vw] h-[10vw] bg-sky-200 rounded-full mb-[5vh] ml-[3vw]'>
                                        <img alt="download" src="/money-bag.png" className='w-[6vw] h-[6vw] mt-[5vh] ml-[2vw]' />
                                    </div>
                                    <div className='mb-[2vh]'><p className='text-center'>Activate Overdraft Facility</p></div>
                                    <div><p className='w-[15vw] text-center'>Opt for the overdraft feature linked to your deposit to access instant funds during emergencies.</p></div>
                                </div>
                                <div><img alt="next" src="/next.png" className='w-[4vw] mt-[6vh] mr-[2vw]' /></div>
                            </div>
                            <div className='flex'>
                                <div className='flex flex-col'>
                                    <div className='w-[10vw] h-[10vw] bg-sky-200 rounded-full mb-[5vh] ml-[3vw]'>
                                        <img alt="download" src="/profile.png" className='w-[6vw] h-[6vw] mt-[5vh] ml-[2vw]' />
                                    </div>
                                    <div className='mb-[2vh]'><p className='text-center'>Manage and Track</p></div>
                                    <div><p className='w-[15vw] text-center'>Monitor your deposit growth and overdraft usage through the app, ensuring financial security and flexibility.</p></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className='bg-blue-100 pt-2'>
                        <p className='font-POP text-3xl font-bold text-center mb-5 mt-16'>How it works ?</p>
                        <div className='flex mt-20 sm:ml-[3vw] sm:mr-[2vw] lg:ml-[7vw] lg:mr-0'>
                            <div>
                                <div className='flex'>
                                    <div className='rounded-full sm:w-[10vh] sm:h-[8vh] lg:w-[15vh] lg:h-[15vh] bg-white'>
                                        <div>
                                            <img className='sm:w-[8vw] md:w-[5vw] sm:ml-2 md:ml-10 lg:ml-5 sm:pt-3 md:pt-10 lg:pt-5' alt="apply_loan" src="/fd.png" />
                                        </div>
                                    </div>
                                    <div>
                                        <img className='sm:w-8 lg:w-12 sm:mt-[2vh] md:mt-[4vh] sm:ml-[2px] sm:mr-[2px] md:ml-[2vw] md:mr-[2vw] lg:ml-[4vw] lg:mr-[4vw]' alt="arrow" src="/next.png" />
                                    </div>
                                </div>
                                <div className='mt-10 mb-10 sm:w-[17vw] md:w-[12vw] sm:text-base md:text-lg'>
                                    <p>Create FD / Flex-FD with Ample Wealth</p>
                                </div>
                            </div>
                            <div>
                                <div className='flex'>
                                    <div className='rounded-full sm:w-[10vh] sm:h-[8vh] lg:w-[15vh] lg:h-[15vh] bg-white'>
                                        <div>
                                            <img className='sm:w-[8vw] md:w-[5vw] sm:ml-2 md:ml-10 lg:ml-5 sm:pt-3 md:pt-10 lg:pt-5' alt="ample_saathi" src="/loan1.png" />
                                        </div>
                                    </div>
                                    <div>
                                        <img className='sm:w-8 lg:w-12 sm:mt-[2vh] md:mt-[4vh] sm:ml-[2px] sm:mr-[2px] md:ml-[2vw] md:mr-[2vw] lg:ml-[4vw] lg:mr-[4vw]' alt="arrow" src="/next.png" />
                                    </div>
                                </div>
                                <div className='mt-10 mb-10 sm:w-[17vw] md:w-[12vw] sm:text-base md:text-lg'>
                                    <p>Get a Overdraft on your FD</p>
                                </div>
                            </div>
                            <div>
                                <div className='flex'>
                                    <div className='rounded-full sm:w-[10vh] sm:h-[8vh] lg:w-[15vh] lg:h-[15vh] bg-white'>
                                        <div>
                                            <img className='sm:w-[8vw] md:w-[5vw] sm:ml-2 md:ml-10 lg:ml-5 sm:pt-3 md:pt-10 lg:pt-5' alt="agri_inputs" src="/emergency_fund.png" />
                                        </div>
                                    </div>
                                    <div>
                                        <img className='sm:w-8 lg:w-12 sm:mt-[2vh] md:mt-[4vh] sm:ml-[2px] sm:mr-[2px] md:ml-[2vw] md:mr-[2vw] lg:ml-[4vw] lg:mr-[4vw]' alt="arrow" src="/next.png" />
                                    </div>
                                </div>
                                <div className='mt-10 mb-10 sm:w-[17vw] md:w-[12vw] sm:text-base md:text-lg'>
                                    <p>Save small for emergency</p>
                                </div>
                            </div>
                            <div>
                                <div className='flex'>
                                    <div className='rounded-full sm:w-[10vh] sm:h-[8vh] lg:w-[15vh] lg:h-[15vh] bg-white'>
                                        <div>
                                            <img className='sm:w-[8vw] md:w-[5vw] sm:ml-2 md:ml-10 lg:ml-5 sm:pt-3 md:pt-10 lg:pt-5' alt="app_download" src="/dream.png" />
                                        </div>
                                    </div>
                                    <div>
                                        <img className='sm:w-8 lg:w-12 sm:mt-[2vh] md:mt-[4vh] sm:ml-[2px] sm:mr-[2px] md:ml-[2vw] md:mr-[2vw] lg:ml-[4vw] lg:mr-[4vw]' alt="arrow" src="/next.png" />
                                    </div>
                                </div>
                                <div className='mt-10 mb-10 sm:w-[17vw] md:w-[12vw] sm:text-base md:text-lg'>
                                    <p>Save for your dream holiday or Goals</p>
                                </div>
                            </div>
                            <div>
                                <div className='flex'>
                                    <div className='rounded-full sm:w-[10vh] sm:h-[8vh] lg:w-[15vh] lg:h-[15vh] bg-white'>
                                        <div>
                                            <img className='sm:w-[8vw] md:w-[5vw] sm:ml-2 md:ml-10 lg:ml-5 sm:pt-3 md:pt-10 lg:pt-5' alt="app_download" src="/family.png" />
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-10 mb-10 sm:w-[17vw] md:w-[12vw] sm:text-base md:text-lg'>
                                    <p>Save for family & education</p>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className='bg-blue-100 pt-[10vh] pb-20'>
                        <div className=''>
                            <p className='text-center text-gray-600 font-POP mb-5'>Ample Savings is Partnered with</p>
                            <p className='text-center text-3xl font-semibold font-POP'>Get attractive FD interest rates upto 9.1% with Ample Savings.</p>
                        </div>
                        <div className='flex mt-20 sm:ml-5 md:ml-20 lg:ml-28'>
                            <div className='bg-white sm:h-[15vh] md:h-[20vh] w-[20vw] mr-5'>
                                <img className='mt-[5vh] w-[15vw] ml-[2vw]' alt="south indian bank" src="/sib.png" />
                            </div>
                            <div className='bg-white  sm:h-[15vh] md:h-[20vh] w-[20vw] mr-5'>
                                <img className='mt-[6vh] w-[15vw] ml-[2vw]' alt="bajaj finserv" src="/nesfb.png" />
                            </div>
                            <div className='bg-white sm:h-[15vh] md:h-[20vh] w-[20vw] mr-5'>
                                <img className='mt-[5vh] w-[15vw] ml-[2vw]' alt="bajaj finserv" src="/utkarsh.svg" />
                            </div>
                            <div className='bg-white sm:h-[15vh] md:h-[20vh] w-[20vw] mr-5'>
                                <img className='mt-[7vh] w-[15vw] ml-[2vw]' alt="bajaj finserv" src="/shivalik.png" />
                            </div>

                        </div>
                        <div className='flex mt-10 sm:ml-5 md:ml-20 lg:ml-28'>
                            <div className='bg-white sm:h-[15vh] md:h-[20vh] w-[20vw] mr-5'>
                                <img className='mt-[7vh] w-[15vw] ml-[2vw]' alt="bajaj finserv" src="/suryoday.svg" />
                            </div>

                        </div>
                    </div>
                    <div>
                        <div className='mt-20'>
                            <p className='text-center text-gray-600 font-POP mb-5 text-xl'>why Ample Savings?</p>
                        </div>
                        <div className='flex sm:flex-col md:flex-row'>
                            <div className='flex flex-col sm:w-[90vw] md:w-[25vw] ml-[8vw] mt-20'>
                                <img className='w-10' alt="flexible" src="/icon-flex.jpg" />
                                <p className='mt-5 font-POP'>Financial Planning</p>
                                <p className='mt-5'>Returns on deposits help farmers better budget for operational costs and future upgrades.</p>
                            </div>
                            <div className='flex flex-col sm:w-[90vw] md:w-[25vw] ml-[8vw] mt-20'>
                                <img className='w-10' alt="flexible" src="/onboarding.png" />
                                <p className='mt-5 font-POP'>Emergency Fund</p>
                                <p className='mt-5'>Deposits provide entrepreneurs with funds to manage unexpected expenses and market fluctuations. </p>
                            </div>
                            <div className='flex flex-col sm:w-[90vw] md:w-[25vw] ml-[8vw] mt-20'>
                                <img className='w-10' alt="flexible" src="/inputs.png" />
                                <p className='mt-5 font-POP'>Save Now, Build Later</p>
                                <p className='mt-5'>Save consistently with high-interest Recurring Deposits to fund your rural business ventures effectively.  </p>
                            </div>
                        </div>
                        <div className='flex sm:flex-col md:flex-row'>
                            <div className='flex flex-col sm:w-[90vw] md:w-[25vw] ml-[8vw] mt-20'>
                                <img className='w-10' alt="flexible" src="/icon-train.jpg" />
                                <p className='mt-5 font-POP'>Overdraft on Emergencies</p>
                                <p className='mt-5'>Access instant funds through overdrafts on deposits, ensuring financial security during critical times.  </p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className='relative mt-[25vh]'>
                            <img alt="footerimage" src="/footer.png" />
                        </div>
                        <div className='bg-[#496E58] flex justify-between pb-12 sm:flex-col lg:flex-row'>
                            <div className='sm:ml-[10vw] lg:ml-20'>
                                <img className='mt-5 mb-5 md:ml-[20vw] lg:ml-0' alt="footer" src="/amplesavings_logo.png" />
                                <img className="sm:w-[40vw] lg:w-[15vw] sm:ml-[15vw] lg:ml-5" alt="comming-soon" src="/comingsoon.png" />
                            </div>
                            <div className='flex flex-col sm:items-center lg:items-start sm:mt-10 lg:mt-0'>
                                <p className='text-white text-2xl font-POP font-bold mb-5'>More Links</p>
                                <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description#Input_Financing">Input Financing</Link>
                                <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description#Payment_Receivables">Payment & Receivables</Link>
                                <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description#Insurance">Insurance</Link>
                                <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description#Farm">Farm Mechanization</Link>
                                <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description#Market">Market Linkage</Link>
                                <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description#Advisory">Advisory</Link>
                                <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description#wealth">Wealth</Link>
                            </div>
                            <div className='flex flex-col sm:items-center lg:items-start sm:mt-10 lg:mt-0'>
                                <p className='text-white text-2xl font-POP font-bold mb-5'>Pages</p>
                                <Link className='text-white text-xl font-POP mb-2 font-normal' to="/company">Company</Link>
                                <Link className='text-white text-xl font-POP mb-2 font-normal' to="/ampleudyam">Udyam</Link>
                                <Link className='text-white text-xl font-POP mb-2 font-normal' to="/amplewealth">Savings</Link>
                                <div className='text-white text-xl font-POP mb-2 font-normal cursor-pointer' onClick={handleModal}>
                                    {isContact === true ? <div><Conatctus onClose={handleModal} /></div> : <p>Contact Us</p>}
                                </div>
                            </div>
                            <div className='flex flex-col sm:mt-10 lg:mt-0 sm:mr-0 lg:mr-20 sm:items-center'>
                                <p className='text-white text-2xl font-POP font-bold mb-5'>Contact with us</p>
                                <div className='flex flex-row'>
                                    <a href='https://amplesavings.com'><img className='w-12 h-12 mr-1' alt="facebook" src="/facebook.png" /></a>
                                    <a href='https://amplesavings.com'><img className='w-12 h-12 mr-3' alt="instagram" src="/Instagram.png" /></a>
                                    <a href='https://amplesavings.com'><img className='w-10 h-10 mr-4 mt-1' alt="linkedin" src="/linkedin.png" /></a>
                                    <a href='https://amplesavings.com'><img className='w-10 h-10 mr-3 mt-1' alt="twitter" src="/twitter.png" /></a>
                                </div>
                                <div className='flex mt-5'>
                                    <img className='w-10 mr-2' src="/email.png" alt="mail icon" />
                                    <Link className='text-white text-lg font-POP mt-1 font-normal' to="#" onClick={(e) => {
                                        window.location.href = "careers@amplesavings.com";
                                        e.preventDefault();
                                    }}>careers@amplesavings.com</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> :
                <div>
                    <div onClick={toggleMenu}>
                        <div className='flex w-full justify-between'>
                            <div className='flex ml-7 mt-5'>
                                <Link to="/"><img className='w-[30vw]' alt="logo" src="/logo_c.png" /></Link>
                            </div>
                            <div className='mt-7'>
                                <img className='w-[7vw] mr-10' alt="links" src="/close.png" />
                            </div>
                        </div>
                    </div>
                    <div>
                        <Link to="/company"><p className='mt-20 text-center font-POP text-xl'>Company</p></Link>
                        <Link to="/ampleudyam"><p className='mt-5 text-center font-POP text-xl'>Udyam</p></Link>
                        <Link to="/amplewealth"><p className='mt-5 text-center font-POP text-xl'>Savings</p></Link>
                    </div>
                </div>
            }
        </div>
    )
}

export default Amplewealth