import { useState } from "react"
import { Link } from 'react-router-dom';


export default function Carousal({ slides }) {
    let [current, setCurrent] = useState(0);

    return (
        <div className="overflow-hidden relative">
            <div className=" flex transition ease-out duration-100" style={{ transform: `translateX(-${current * 100}%)` }}>
                {
                    slides.map((s) => {
                        return (
                            <img className="w-[100vw] h-[100vh] brightness-50 opacity-70" src={s} alt={s} />
                        )
                    })
                }
            </div>
            <div className="md:absolute md:bottom-0 md:flex md:justify-center md:gap-0 md:w-full sm:hidden">
                <div onClick={() => {
                    setCurrent(0)
                }} className={`${current === 0 ? 'bg-[#0f4022]' : 'bg-gray-800 opacity-40'} px-5 py-5 rounded-tl-lg text-white cursor-pointer font-POP`}>
                    what we do ?
                </div>
                <div onClick={() => {
                    setCurrent(1)
                }} className={`${current === 1 ? 'bg-[#0f4022]' : 'bg-gray-800 opacity-40'} px-5 py-5 text-white cursor-pointer font-POP`}>
                    Udyam
                </div>
                <div onClick={() => {
                    setCurrent(2)
                }} className={`${current === 2 ? 'bg-[#0f4022]' : 'bg-gray-800 opacity-40'} px-5 py-5 text-white cursor-pointer font-POP`}>
                    Savings
                </div>
                <div onClick={() => {
                    setCurrent(3)
                }} className={`${current === 3 ? 'bg-[#0f4022]' : 'bg-gray-800 opacity-40'} px-5 py-5 rounded-tr-lg text-white cursor-pointer font-POP`}>
                    we are hiring
                </div>
            </div>
            <div className="absolute ml-[27vw] bottom-5 flex justify-center gap-0 w-full md:hidden">
                <div onClick={() => {
                    setCurrent(0)
                }} className={`${current === 0 ? 'bg-[#0f4022]' : 'bg-gray-800 opacity-40'} w-6 h-6 mr-2 rounded-full cursor-pointer`}>
                </div>
                <div onClick={() => {
                    setCurrent(1)
                }} className={`${current === 1 ? 'bg-[#0f4022]' : 'bg-gray-800 opacity-40'} w-6 h-6 mr-2 rounded-full cursor-pointer`}>

                </div>
                <div onClick={() => {
                    setCurrent(2)
                }} className={`${current === 2 ? 'bg-[#0f4022]' : 'bg-gray-800 opacity-40'} w-6 h-6 mr-2 rounded-full cursor-pointer`}>

                </div>
                <div onClick={() => {
                    setCurrent(3)
                }} className={`${current === 3 ? 'bg-[#0f4022]' : 'bg-gray-800 opacity-40'} w-6 h-6 mr-2 rounded-full cursor-pointer`}>

                </div>
            </div>
            {
                current === 0 &&
                <div>
                    <div className='absolute flex flex-col top-[25vh]'>
                        <p className='ml-10 text-white font-POP font-medium sm:text-sm sm:w-[80vw] md:w-full md:text-xl'>Building Financial Technology for next livestock-revolution</p>
                        <p className='ml-10 mt-5 text-white font-POP font-bold sm:text-xl md:text-5xl'>Empowering Bharat:</p>
                        <p className='ml-10 sm:mt-2 lg:mt-5 text-white font-POP font-bold sm:text-xl md:text-5xl'>From Roots to Prosperity</p>
                    </div>
                    <div className='absolute flex top-[50vh] flex-col cursor-pointer'>
                        <div className='flex flex-row sm:mr-[5vw] md:mr-0'>
                            <Link to={'/description#Input_Financing'} ><div className='sm:px-[10px] sm:py-[5px] md:px-3 md:py-3 bg-[#0f4022] ml-10 rounded-md text-white sm:text-sm md:text-xl font-POP'>Input Financing</div></Link>
                            <Link to={'/description#Payment_Receivables'} ><div className='sm:px-[10px] sm:py-[5px] md:px-3 md:py-3 bg-[#0f4022] ml-5 rounded-md text-white sm:text-sm md:text-xl font-POP'>Payment & Receivables</div></Link>
                            <Link to={'/description#Insurance'} ><div className='sm:px-[10px] sm:py-[5px] md:px-3 md:py-3 bg-[#0f4022] ml-5 rounded-md text-white sm:text-sm md:text-xl font-POP'>Insurance</div></Link>

                        </div>
                        <div className='flex flex-row mt-5'>
                            <Link to={'/description#Farm'} ><div className='sm:px-[10px] sm:py-[5px] md:px-3 md:py-3 bg-[#0f4022] ml-10 rounded-md text-white sm:text-sm md:text-xl font-POP'>Farm Mechanisation</div></Link>
                            <Link to={'/description#Market'} ><div className='sm:px-[10px] sm:py-[5px] md:px-3 md:py-3 bg-[#0f4022] ml-5 rounded-md text-white sm:text-sm md:text-xl font-POP'>Market Linkage</div></Link>
                        </div>
                        <div className='flex flex-row mt-5'>

                            <Link to={'/description#Advisory'} ><div className='sm:px-[10px] sm:py-[5px] md:px-3 md:py-3 bg-[#0f4022] ml-10 rounded-md text-white sm:text-sm md:text-xl font-POP'>Advisory</div></Link>
                            <Link to={'/description#wealth'} ><div className='sm:px-[10px] sm:py-[5px] md:px-3 md:py-3 bg-[#0f4022] ml-5 rounded-md text-white sm:text-sm md:text-xl font-POP'>Wealth Creation</div></Link>
                        </div>
                    </div>
                </div>
            }
            {
                current === 1 &&
                <div>
                    <div className='absolute flex flex-col top-[25vh]'>
                        <p className='ml-10 text-white font-POP font-medium sm:text-sm sm:w-[80vw] md:w-full md:text-xl'>Driving the Next Revolution in Allied Agri-Tech</p>
                        <p className='ml-10 mt-5 text-white font-POP font-bold sm:text-xl md:text-3xl'>Ample Udyam : Empowering Livestock Farmers </p>
                        <p className='ml-10 mt-5 text-white font-POP font-bold sm:text-xl md:text-3xl sm:w-[60%] md:w-[50%]'>Unlocking potential with flexible financing, insurance, market access, and receivables management.</p>
                    </div>
                    <div className='absolute flex sm:top-[80vh] md:top-[65vh] flex-col cursor-pointer'>
                        <div className='flex flex-row'>
                            <Link to="/ampleudyam" className='px-3 py-3 bg-[#0f4022] ml-10 rounded-md text-white font-POP'>Know More</Link>
                        </div>
                    </div>
                </div>
            }
            {
                current === 2 &&
                <div>
                    <div className='absolute flex flex-col top-[28vh]'>
                        <p className='ml-10 text-white font-POP font-medium sm:text-sm sm:w-[80vw] md:w-full md:text-xl'>Invest in Savings for Stability and Growth</p>
                        <p className='ml-10 mt-5 text-white font-POP font-bold sm:text-xl md:text-5xl'>Securing Bharat’s future: </p>
                        <p className='ml-10 mt-5 text-white font-POP font-bold sm:text-xl md:text-5xl sm:w-[60%] md:w-[50%]'>Invest in savings for emergencies, education, and entrepreneurship.</p>
                    </div>
                    <div className='absolute flex top-[65vh] flex-col cursor-pointer'>
                        <div className='flex flex-row'>
                            <Link to="/amplewealth" className='px-3 py-3 bg-[#0f4022] ml-10 rounded-md text-white font-POP'>Know More</Link>
                        </div>
                    </div>
                </div>
            }
            {
                current === 3 &&
                <div>
                    <div className='absolute flex flex-col top-[25vh]'>
                        <p className='ml-10 text-white font-POP font-medium sm:text-s sm:w-[80vw] md:w-full md:text-xl'>Unlocking Transformative Opportunities in Livestock</p>
                        <p className='ml-10 mt-5 text-white font-POP font-bold sm:text-xl md:text-5xl w-[50%] leading-tight'>Phenomenal opportunities in Livestock Sector</p>
                    </div>
                    <div className='absolute flex sm:top-[50vh] lg:top-[60vh] flex-col cursor-pointer'>
                        <div className='flex flex-row'>
                            <div className='px-3 py-3 bg-[#0f4022] ml-10 rounded-md text-white font-POP'>Know More</div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}