import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import Conatctus from './Conatctus';

function Company() {
  const [isOpen, setIsOpen] = useState(false)
  const [isContact, setIsContact] = useState(false)
  const toggleMenu = () => {
    setIsOpen((open) => !open)
  }
  const handleModal = () => {
    console.log(isContact);
    setIsContact((open) => !open); // Update parent state to close modal
    console.log(isContact);
  };
  return (
    <div>
      {isOpen === false ?
        <div>
          <div className='flex top-5 w-full justify-between sm:mt-[3vh] lg:mt-0'>
            <div className='flex md:ml-10 sm:ml-7'>
              <Link to="/"><img className='sm:w-[30vw] md:w-[20vw] lg:w-[15vw]' alt="logo" src="/logo_c.png" /></Link>
            </div>
            <div className='flex md:mt-2 lg:mt-5 md:flex md:items-center md:mr-8 lg:mr-16 sm:hidden'>
              <Link className='md:mr-2 md:text-lg lg:mr-10 lg:text-xl font-POP font-semibold' to="/company">Company</Link>
              <Link className='md:mr-2 md:text-lg lg:mr-10 lg:text-xl font-POP font-semibold' to="/ampleudyam">Udyam</Link>
              <Link className='md:mr-2 md:text-lg lg:mr-10 lg:text-xl font-POP font-semibold' to="/amplewealth">Savings</Link>
            </div>
            <div className='sm:flex sm:items-end md:hidden' onClick={toggleMenu}>
              <img className='sm:w-[10vw] mr-5' alt="links" src="/menu.png" />
            </div>
          </div>
          <div className='relative sm:top-[12vh] lg:top-[10vh] flex flex-col'>
            <div className='mt-14 sm:w-[90vw] lg:w-[96vw] sm:ml-[2vw] lg:ml-2'>
              <p className='ml-[3vw] text-5xl font-POP font-medium text-center mb-10'>About Us</p>
              <p className='ml-[3vw] mt-5 text-slate-500 sm:text-base md:text-xl text-center'>With deep insights into rural value chains, we empower livestock farmers by providing timely access to affordable credit, insurance, market linkage,cash flow and receivables management through world-class technology. This support not only helps businesses grow but also lays the foundation for wealth creation. By enabling investments in better infrastructure, equipment, and operations, we help rural entrepreneurs unlock their full potential.</p>
              <p className='ml-[3vw] mt-5 text-slate-500 sm:text-base md:text-xl text-center'>Our mission extends beyond financial services to transforming the livelihoods of over 250 million people in rural India. Through sustained growth, improved financial stability, and the creation of long-term wealth, we aim to build thriving rural communities that contribute to a stronger economy.</p>
              {/* <p className='ml-[3vw] mt-5 text-slate-500 text-xl text-center'>Founded by the alumni from IIT Delhi, IIT Kharagpur, IIM Ahmedabad & other top institutes - DeHaat is a fully funded start-up with an exceptional growth rate & is certified Great Place to Work 2 years in a row, 2022-2023 & 2023-2024. The impact we have created at grass root level over the last 12 years of our operations has been phenomenal  & is recognized & felicitated by NASSCOM, Forbes, ET, Niti Aayog, Bill Gates Foundation & many more.</p> */}
            </div>
            <div className='flex relative sm:mt-[5vh] lg:mt-[10vh] sm:flex-col lg:flex-row md:ml-5 lg:ml-0 sm:items-center lg:items-start'>
              <div className='sm:w-[90vw] lg:w-[30vw] h-[30vh] bg-[#0f4022] rounded-tl-[15vh] rounded-b-[2vh] rounded-tr-[2vh] lg:ml-10 mt-16'>
                <div className='absolute'>
                  <p className='sm:mt-12 md:mt-20 lg:mt-12 sm:ml-[10vw] lg:ml-[4vw] font-POP sm:text-2xl md:text-5xl lg:text-2xl font-bold text-white'>Pruthiraj Rath</p>
                  <a href="https://www.linkedin.com/in/pruthiraj-rath-91040a111"><img className='sm:w-10 sm:ml-12 sm:mt-[10vh] md:w-20 md:ml-24 lg:w-10 lg:ml-12 lg:mt-[10vh]' alt="Linkedin_pruthiraj" src="/linkedin.png" /></a>
                </div>
                <div>
                  <img className='rounded-full border-4 border-white sm:h-[17vh] md:h-[20vh] sm:ml-[52vw] lg:ml-[17vw] sm:mt-[12vh] md:mt-[7vh]' alt="photo_pruthiraj" src="/p_photo.jpeg" />
                </div>
              </div>
              <div className='sm:w-[90vw] lg:w-[30vw] h-[30vh] bg-[#0f4022] rounded-tl-[15vh] rounded-b-[2vh] rounded-tr-[2vh] lg:ml-10 mt-16'>
                <div className='absolute'>
                  <p className='sm:mt-12 md:mt-20 lg:mt-12 sm:ml-[10vw] lg:ml-[4vw] font-POP sm:text-2xl md:text-5xl lg:text-2xl font-bold text-white'>Raj Senapati</p>
                  <a href="https://www.linkedin.com/in/rajsenapati"><img className='sm:w-10 sm:ml-12 sm:mt-[10vh] md:w-20 md:ml-24 lg:w-10 lg:ml-12 lg:mt-[10vh]' alt="Linkedin_raj" src="/linkedin.png" /></a>
                </div>
                <div>
                  <img className='rounded-full border-4 border-white sm:h-[17vh] md:h-[20vh] sm:ml-[52vw] lg:ml-[17vw] sm:mt-[12vh] md:mt-[7vh]' alt="photo_pruthiraj" src="/r_photo.jpeg" />
                </div>
              </div>
              <div className='sm:w-[90vw] lg:w-[30vw] h-[30vh] bg-[#0f4022] rounded-tl-[15vh] rounded-b-[2vh] rounded-tr-[2vh] lg:ml-10 mt-16'>
                <div className='absolute'>
                  <p className='sm:mt-12 md:mt-20 lg:mt-12 sm:ml-[10vw] lg:ml-[4vw] font-POP sm:text-2xl md:text-5xl lg:text-2xl font-bold text-white'>Udit Mishra</p>
                  <a href="https://www.linkedin.com/in/udit-prasad-mishra-028007b9"><img className='sm:w-10 sm:ml-12 sm:mt-[10vh] md:w-20 md:ml-24 lg:w-10 lg:ml-12 lg:mt-[10vh]' alt="Linkedin_udit" src="/linkedin.png" /></a>
                </div>
                <div>
                  <img className='rounded-full border-4 border-white sm:h-[17vh] md:h-[20vh] sm:ml-[52vw] lg:ml-[17vw] sm:mt-[12vh] md:mt-[7vh]' alt="photo_pruthiraj" src="/u_photo.jpeg" />
                </div>
              </div>
            </div>
          </div>
          <div className='relative mt-[30vh] sm:ml-[5vw] lg:ml-[3vw] flex sm:flex-col lg:flex-row'>
            <div className='sm:w-[90vw] sm:mb-10 lg:mb-0 lg:w-[32vw]'>
              <img className='h-[15vh] sm:ml-[30vw] md:ml-[35vw] lg:ml-[12vw] mb-5' alt="mission" src="/mission.png" />
              <p className='text-center mb-5 font-POP text-2xl font-semibold'>Mission</p>
              <p className='text-center font-POP text-xl font-extralight'>To empower every micro-entrepreneur in India with seamless access to financial solutions, transforming resources into wealth. We aim to foster a thriving and sustainable agricultural ecosystem by making modern farming accessible to all, boosting productivity, and enhancing rural livelihoods.</p>
            </div>
            <div className='sm:w-[90vw] sm:mb-10 lg:mb-0 lg:w-[32vw]'>
              <img className='h-[15vh] sm:ml-[30vw] md:ml-[35vw] lg:ml-[12vw] mb-5' alt="mission" src="/sight.png" />
              <p className='text-center mb-5 font-POP text-2xl font-semibold'>Vision</p>
              <p className='text-center font-POP text-xl font-extralight'>To bridge the financing gap for micro-entrepreneurs by offering innovative financial services, including input financing, advisory support, and digital tools. We empower entrepreneurs to ensure the long-term sustainability of their operations while driving wealth creation through tailored investment advisory. </p>
            </div>
            <div className='sm:w-[90vw] lg:w-[32vw]'>
              <img className='h-[15vh] sm:ml-[30vw] md:ml-[35vw] lg:ml-[12vw] mb-5' alt="mission" src="/customer.png" />
              <p className='text-center mb-5 font-POP text-2xl font-semibold'>Goals</p>
              <p className='text-center font-POP text-xl font-extralight'>Increase access to credit and financial services for micro-entrepreneurs. Promote sustainable practices by supporting high-quality inputs and modern farming techniques. Strengthen market linkages, connecting entrepreneurs with reliable buyers, suppliers, and value chains for better price realization. Leverage technology to improve the efficiency and accessibility of financing solutions. Facilitate wealth creation for livestock entrepreneurs with tailored financial solutions.</p>
            </div>
          </div>
          <div className='relative mt-[25vh]'>
            <img alt="footerimage" src="/footer.png" />
          </div>
          <div className='bg-[#496E58] flex justify-between pb-12 sm:flex-col lg:flex-row'>
            <div className='sm:ml-[10vw] lg:ml-20'>
              <img className='mt-5 mb-5 md:ml-[20vw] lg:ml-0' alt="footer" src="/amplesavings_logo.png" />
              <img className="sm:w-[40vw] lg:w-[15vw] sm:ml-[15vw] lg:ml-5" alt="comming-soon" src="/comingsoon.png" />
            </div>
            <div className='flex flex-col sm:items-center lg:items-start sm:mt-10 lg:mt-0'>
              <p className='text-white text-2xl font-POP font-bold mb-5'>More Links</p>
              <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description#Input_Financing">Input Financing</Link>
              <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description#Payment_Receivables">Payment & Receivables</Link>
              <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description#Insurance">Insurance</Link>
              <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description#Farm">Farm Mechanization</Link>
              <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description#Market">Market Linkage</Link>
              <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description#Advisory">Advisory</Link>
              <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description#wealth">Wealth</Link>
            </div>
            <div className='flex flex-col sm:items-center lg:items-start sm:mt-10 lg:mt-0'>
              <p className='text-white text-2xl font-POP font-bold mb-5'>Pages</p>
              <Link className='text-white text-xl font-POP mb-2 font-normal' to="/company">Company</Link>
              <Link className='text-white text-xl font-POP mb-2 font-normal' to="/ampleudyam">Udyam</Link>
              <Link className='text-white text-xl font-POP mb-2 font-normal' to="/amplewealth">Savings</Link>
              <div className='text-white text-xl font-POP mb-2 font-normal cursor-pointer' onClick={handleModal}>
                {isContact === true ? <div><Conatctus onClose={handleModal} /></div> : <p>Contact Us</p>}
              </div>
            </div>
            <div className='flex flex-col sm:mt-10 lg:mt-0 sm:mr-0 lg:mr-20 sm:items-center'>
              <p className='text-white text-2xl font-POP font-bold mb-5'>Contact with us</p>
              <div className='flex flex-row'>
                <a href='https://amplesavings.com'><img className='w-12 h-12 mr-1' alt="facebook" src="/facebook.png" /></a>
                <a href='https://amplesavings.com'><img className='w-12 h-12 mr-3' alt="instagram" src="/Instagram.png" /></a>
                <a href='https://amplesavings.com'><img className='w-10 h-10 mr-4 mt-1' alt="linkedin" src="/linkedin.png" /></a>
                <a href='https://amplesavings.com'><img className='w-10 h-10 mr-3 mt-1' alt="twitter" src="/twitter.png" /></a>
              </div>
              <div className='flex mt-5'>
                <img className='w-10 mr-2' src="/email.png" alt="mail icon" />
                <Link className='text-white text-lg font-POP mt-1 font-normal' to="#" onClick={(e) => {
                  window.location.href = "careers@amplesavings.com";
                  e.preventDefault();
                }}>careers@amplesavings.com</Link>
              </div>
            </div>
          </div>
        </div> :
        <div>
          <div onClick={toggleMenu}>
            <div className='flex w-full justify-between'>
              <div className='flex ml-7 mt-5'>
                <Link to="/"><img className='w-[30vw]' alt="logo" src="/logo_c.png" /></Link>
              </div>
              <div className='mt-7'>
                <img className='w-[7vw] mr-10' alt="links" src="/close.png" />
              </div>
            </div>
          </div>
          <div>
            <Link to="/company"><p className='mt-20 text-center font-POP text-xl'>Company</p></Link>
            <Link to="/ampleudyam"><p className='mt-5 text-center font-POP text-xl'>Udyam</p></Link>
            <Link to="/amplewealth"><p className='mt-5 text-center font-POP text-xl'>Savings</p></Link>
          </div>
        </div>
      }
    </div>
  )
}

export default Company